import React from "react";
import {
  DialogActions,
  DialogContentText,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import { warning } from "../../assets";
import { useDispatch, useSelector } from "react-redux";
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../../slices/notifier";
import { createArticles, emptyArticleContent } from "../../slices/helpdesk";
import { EditorState } from "draft-js";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";

const AssignOperatorModal = ({ id, open, handleClose, data }) => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const showSnackbar = (data) => {
    // NOTE:
    // if you want to be able to dispatch a `closeSnackbar` action later on,
    // you SHOULD pass your own `key` in the options. `key` can be any sequence
    // of number or characters, but it has to be unique for a given snackbar.
    enqueueSnackbar({
      message: t(data.message),
      options: {
        key: new Date().getTime() + Math.random(),
        variant: data.variant,
        action: (key) => (
          <Button style={{ color: "#fff" }} onClick={() => closeSnackbar(key)}>
            {t("dismiss me")}
          </Button>
        ),
      },
    });
  };
  const { func } = useSelector((state) => state.helpdesk);

  return (
    <Dialog open={open} onClose={() => handleClose(id)} className="info-modal">
      <LazyLoadImage src={warning} className="assign-icon" alt="assign-icon" />
      <DialogTitle className="assign-operator-title">
        {t("Do you want to save this article as a draft?")}
      </DialogTitle>
      <DialogContent style={{ overflow: "hidden" }}>
        <DialogContentText className="assign-operator-text">
          {t(
            "by clicking on save this article will be saved in the draft folder. if  you click cancel this article will be automaticlly deleted."
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="assign-operator-btns">
        <Button
          className="assign-operator-cancel-btn"
          onClick={() => handleClose(id)}
        >
          {t("Cancel")}
        </Button>
        <Button
          id={"save-draft-btn"}
          color="primary"
          style={{ width: "159px" }}
          onClick={() => {
            dispatch(
              createArticles({
                websiteID: user?.websiteID,
                data: {
                  ...data,
                  category: data?.category ? data?.category : undefined,
                },
              })
            ).then((res) => {
              if (res.meta.requestStatus === "fulfilled") {
                func(EditorState.createEmpty());

                showSnackbar({
                  variant: "success",
                  message: "Article saved as Draft",
                });
                dispatch(emptyArticleContent());
                handleClose(id);
              } else {
                showSnackbar({
                  variant: "error",
                  message: res.error.message,
                });
              }
            });
          }}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignOperatorModal;
